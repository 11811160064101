<template>
    <vx-card title="Stock Transfer Order Processing">
        <div class="vx-input-group flex">
            <!-- <vs-tabs v-if="render && settingValue == 'Auto'" v-model="$route.params.status">
                <vs-tab label="Process" :disabled="settingValue == 'Auto'">
                    <tab-process></tab-process>
                </vs-tab>
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>

            <vs-tabs v-if="render && settingValue == 'Standart'" v-model="$route.params.status">
                <vs-tab label="Process">
                    <tab-process></tab-process>
                </vs-tab>
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs> -->

            <vs-tabs v-model="$route.params.status">
                <vs-tab label="Handover">
                    <tab-handover></tab-handover>
                </vs-tab>
                <vs-tab label="Complete">
                    <tab-complete></tab-complete>
                </vs-tab>
            </vs-tabs>
        </div>
    </vx-card>
</template>

<script>
// import TabProcess from "./TabProcess.vue";
import TabHandover from "./TabHandover.vue";
import TabComplete from "./TabComplete.vue";

export default {
    components: {
        // TabProcess,
        TabHandover,
        TabComplete,
    },
    data() {
        return {
            activeTab: "Handover",
            render: true,
            settingValue: "",
        }
    },
    methods: {
        // ...
    },
    mounted() {
        /* this.$vs.loading();
        this.$http.get("/api/wms/v1/outbound/sto-processing/setting-value")
        .then((resp) => {
            if (resp.code == 200) {
                this.settingValue = resp.data
            }

            if (this.settingValue === 'Auto') {
                this.$route.params.status = 1
            }

            this.render = false
            this.$nextTick(() => this.render = true)
            this.$vs.loading.close();
        })
        .catch((error) => {
            this.$vs.loading.close();
            console.log(error);
        }); */
    },
}
</script>