<template>
    <div>
        <vs-table 
            search 
            stripe 
            border 
            description 
            :sst="true" 
            :data="table.data" 
            :max-items="table.length"
            :total="table.total" 
            @search="handleSearch" 
            @change-page="handleChangePage" 
            @sort="handleSort"
            >
            <template slot="header">
                <vs-dropdown vs-trigger-click class="cursor-pointer">
                    <div class="p-3 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                        <span class="mr-2">{{ this.table.start }} - {{ this.table.end }} of {{ this.table.total }}</span>
                        <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <vs-dropdown-menu>
                        <vs-dropdown-item v-for="item in table.limits" :key="item" @click="handleChangelength(item)">
                            <span>{{ item }}</span>
                        </vs-dropdown-item>
                    </vs-dropdown-menu>
                </vs-dropdown>
            </template>

            <template slot="thead">
                <vs-th sort-key=""></vs-th>
                <vs-th sort-key="">STO Data</vs-th>
                <vs-th sort-key="">Picking List Data</vs-th>
                <vs-th sort-key="">Dest. Warehouse</vs-th>
                <vs-th sort-key="">Delivery Date (ETD)</vs-th>
                <vs-th sort-key="">ETA</vs-th>
                <vs-th sort-key=""># of SKU</vs-th>
            </template>
            <template slot-scope="{ data }">
                <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td>
                        <div class="vx-input-group flex">
                            <vs-button size="small" color="warning" icon-pack="feather" icon="icon-eye" 
                                title="Detail" style="margin-right: 5px" @click="handleDetail(tr.id)" />
                        </div>
                    </vs-td>
                    <vs-td>{{ tr.sto_plan_code }}</vs-td>
                    <vs-td>{{ tr.picking_code }}</vs-td>
                    <vs-td>{{ tr.dest_warehouse_code }} {{ tr.dest_warehouse_name }}</vs-td>
                    <vs-td>{{ tr.req_delivery_date }}</vs-td>
                    <vs-td>{{ tr.eta }}</vs-td>
                    <vs-td>{{ tr.total_sku }}</vs-td>
                </vs-tr>
            </template>
        </vs-table>
        <vs-pagination style="padding-top: 5px" :total="table.totalPage" v-model="setPage" />
    </div>
</template>

<script>
export default {
    components: {},
    props: {
        draw: {
            type: Number,
        },
        stoNumberId: {
            type: Number,
        },
        pickingNumberId: {
            type: Number,
        },
        hoDocId: {
            type: Number,
        },
        status: {
            type: String,
            default: "Complete",
        },
    },
    data() {
        return {
            deleteId: null,
            action: null,
            table: this.tableDefaultState(),
            checked: [],
            checkedAll: false,
            selectedRouteAssignmentToAssign: [],
        };
    },
    methods: {
        tableDefaultState() {
            return {
                data: [],
                length: 10,
                page: 1,
                search: "",
                order: "id",
                sort: "desc",
                total: 0,
                totalPage: 1,
                totalSearch: 0,
                limits: [10, 25, 50, 100, "All"],
                start: 1,
                end: 0,
            };
        },
        handleSearch(searching) {
            this.table.search = searching;
            this.table.page = 1;
            this.getData();
        },
        handleChangePage(page) {
            this.table.page = page;
            this.getData();
        },
        handleSort(key, active) {
            this.table.order = key;
            this.table.sort = active;
            this.getData();
        },
        handleChangelength(val) {
            this.table.length = val == "All" ? this.table.total : val;
            this.table.page = 1;
            this.getData();
        },
        handleClose() {
            this.getData();
            this.$emit("close");
        },
        getData() {
            this.$vs.loading();
            this.$http
                .get("/api/wms/v1/outbound/sto-processing", {
                    params: {
                        length: this.table.length,
                        page: this.table.page,
                        search: this.table.search,
                        order: this.table.order,
                        sort: this.table.sort,
                        sto_number_id: (this.stoNumberId) 
                            ? this.stoNumberId 
                            : null,
                        pl_number_id: (this.pickingNumberId) 
                            ? this.pickingNumberId 
                            : null,
                        ho_doc_id: (this.hoDocId) 
                            ? this.hoDocId 
                            : null,
                        status: this.status,
                    },
                })
                .then((resp) => {
                    if (resp.code == 200) {
                        this.checkedAll = false;
                        this.checked = [];
                        this.selectedRouteAssignmentToAssign = [];

                        this.table.total = resp.data.total_record;
                        this.table.totalPage = resp.data.total_page;
                        this.table.totalSearch = resp.data.total_record_search;
                        this.table.length = resp.data.total_record_per_page;
                        this.table.data = resp.data.records;
                        this.setStartEnd();
                        this.$vs.loading.close();
                    } else {
                        this.$vs.loading.close();
                    }
                })
                .catch((error) => {
                    this.$vs.loading.close();
                    console.log(error);
                });
        },
        setStartEnd() {
            let valStart =
                this.table.length * this.table.page - this.table.length + 1;

            if (valStart > this.table.total) {
                valStart = 1;
            }
            if (this.table.total == 0) {
                valStart = 0;
            }
            let valEnd = this.table.length * this.table.page;

            if (valEnd > this.table.total) {
                valEnd = this.table.total;
            }

            if (
                this.table.totalSearch < this.table.total &&
                this.table.search != ""
            ) {
                valEnd = this.table.totalSearch;
            }

            this.table.start = valStart;
            this.table.end = valEnd;
        },
        handleDetail(id) {
            this.$router.push({
                name: "outbound.stock-transfer-order-processing-complete-detail",
                params: { id: id },
            });
        },
    },
    mounted() { },
    watch: {
        draw() {
            this.getData();
        },
    },
    computed: {
        setPage: {
            get() {
                return 1;
            },
            set(val) {
                this.handleChangePage(val);
            },
        },
    },
};
</script>
  