<template>
    <div>
        <div class="vx-row ml-1 mt-3">
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>STO Number</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <StoNumber @selected="(val) => { this.selectedStoNumber = val }" :dropdown="'sto-doc'" :status="activeTab"></StoNumber>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Picking List Number</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <StoNumber @selected="(val) => { this.selectedPickingNumber = val }" :dropdown="'sto-picking-doc'" :status="activeTab"></StoNumber>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center">
                    <span>Handover Document</span>
                </div>
                <div class="vx-col sm:w-3/4 w-full">
                    <HoDocs @selected="(val) => { this.selectedHoDocument = val }" :status="activeTab"></HoDocs>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:70%">
                <div class="vx-col sm:w-1/4 w-full flex items-center"></div>
                <div class="vx-col sm:w-3/4 w-full">
                    <vs-button color="primary" @click="handleFilter()">Filter</vs-button>
                </div>
            </div>
        </div>
        <div class="vx-row mt-3">
            <div class="vx-col w-full mb-base">
                <TableComplete 
                    :draw="draw" 
                    :status="activeTab" 
                    :sto-number-id="(this.selectedStoNumber) ? this.selectedStoNumber.id : 0"
                    :picking-number-id="(this.selectedPickingNumber) ? this.selectedPickingNumber.id : 0"
                    :ho-doc-id="this.selectedHoDocument ? this.selectedHoDocument.id : 0">
                </TableComplete>
            </div>
        </div>
    </div>
</template>

<script>
import StoNumber from "./component/StoNumber.vue";
import PlNumber from "./component/PL_Number.vue";
import HoDocs from "./component/HoDocs.vue";
import TableComplete from "./component/TableComplete.vue";

export default {
    components: {
        StoNumber,
        PlNumber,
        HoDocs,
        TableComplete
    },
    data() {
        return {
            activeTab: "Complete",
            draw: 0,
            selectedStoNumber: null,
            selectedPickingNumber: null,
            selectedHoDocument: null,
        }
    },
    methods: {
        handleFilter() {
            this.draw++
        },
    }
}
</script>