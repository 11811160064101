<template>
    <v-select 
        :multiple="multiple" 
        :filterable="true" 
        :options="options" 
        v-model="selected"
        :placeholder="placeholder"
        :dropdown="dropdown"
        :status="status" />
</template>

<script>
import vSelect from "vue-select";

export default {
    components: {
        vSelect,
    },
    props: {
        multiple: {
            type: Boolean,
            default: false,
        },
        draw: {
            type: Number,
        },
        dropdown: {
            type: String,
        },
        placeholder: {
            type: String
        },
        value: {
            type: Object
        },
        status: {
            type: String,
        },
    },
    data() {
        return {
            baseUrl: "/api/wms/v1/outbound/sto-processing/get-plan-or-picking-doc-list",
            options: [],
            selected: (this.multiple) ? [] : null
        };
    },
    methods: {
        get() {
            this.$http
                .get(this.baseUrl, {
                    params: {
                        dropdown: this.dropdown,
                        status: this.status,
                    }
                })
                .then(resp => {
                    if (resp.code == 200) {
                        resp.data.records.map(function (x) {
                            return (x.label = x.code);
                        });

                        this.options = resp.data.records;
                    } else {
                        this.options = []
                        console.log(resp.data);
                    }
                });
        }
    },
    watch: {
        selected(v) {
            this.selected = v;
            this.$emit("selected", v);
        },
        value(v) {
            if (v) {
                this.selected = v
            }
        }
    },
    mounted() {
        this.get();

    }
};
</script>